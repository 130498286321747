"use client";
import { useEffect, useRef, useState } from "react";

import clsx from "clsx";
import { debounce } from "lodash-es";
import { InView } from "react-intersection-observer";

import { ClickServer } from "shared-lib/clickserver";
import * as gtag from "shared-lib/gtag";
import { useCustomGate } from "shared-lib/helpers/customStatSigHooks";
import { brand, PRODUCTION_BUILD } from "shared-lib/helpers/envFunctions";
import { log } from "shared-lib/helpers/logging";
import {
  TRACKING_EVENTS,
  logStatsig,
  TRACKING_CATEGORIES,
} from "shared-lib/helpers/statsigFunctions";
import { isMobile } from "shared-lib/helpers/utilityFunctions";

import {
  EZOIC_DESKTOP_PLACEHOLDERS,
  EZOIC_MOBILE_PLACEHOLDERS,
} from "@/lib/constants";

type AdProps = {
  className?: string;
  id: string;
  style?: object;
  debugClassName?: string;
  ezoicId?: string;
  ezoicDebugId?: string;
  ezoicClasses?: string;
  isEzoic?: boolean;
  /*
    Restricts partner that a particular
    ad unit is used with. Defaults to ANY.
    But allows us to kick out before rendering
    the ad unit with the wrong partner.
    Allows us to move the ad partner gate which
    requires a client component down one level.
  */
  useWithPartner?: "ANY" | "ATD" | "EZOIC";
  screen?: "ANY" | "DESKTOP" | "MOBILE";
};

export default function Ad({
  className = "",
  id,
  style = {},
  debugClassName = "",
  ezoicId = "",
  ezoicDebugId = "",
  ezoicClasses = "",
  // isEzoic = false,
  useWithPartner = "ANY",
  screen = "ANY",
}: AdProps) {
  const usingEzoic = useCustomGate("ezoic_ads");
  const partner = usingEzoic ? "EZOIC" : "ATD";

  const isPartnerEzoic = partner === "EZOIC";
  const debugging = process.env.NEXT_PUBLIC_AUTOMATAD_STAGING === "true";
  const completeAdId = isPartnerEzoic
    ? `ezoic-pub-ad-placeholder-${ezoicId || ezoicDebugId}`
    : `${partner}_${brand.adPrefix}_${id}`;

  const containerEl = useRef(null);
  const [shouldDisplay, setShouldDisplay] = useState(true);

  const timerRef = useRef();
  const leavesViewRef = useRef();
  const [, setTime] = useState(0);
  const [, setTimeOutOfView] = useState(0);
  const [fired, setFired] = useState(false);

  const showingMobile = isMobile();

  if (isPartnerEzoic) {
    className = `mx-auto print:hidden flex justify-around ${ezoicClasses}`;
  }

  const adClicked = () => {
    gtag.event({
      action: TRACKING_EVENTS.click_display_ad,
      category: TRACKING_CATEGORIES.interaction,
      label: completeAdId,
    });

    logStatsig(TRACKING_EVENTS.click_display_ad, {
      ad_slot: completeAdId,
    });

    ClickServer.track(TRACKING_EVENTS.click_display_ad, {
      ad_slot: completeAdId,
    });
  };

  useEffect(() => {
    const determineDisplayability = debounce(() => {
      if (containerEl && window) {
        const el = containerEl.current as unknown as Element;
        const curEl = el as HTMLElement;
        const newDisplayValue = curEl?.offsetParent !== null;

        setShouldDisplay(newDisplayValue);
      }
    }, 1000);

    determineDisplayability();

    window.addEventListener("resize", determineDisplayability);

    return () => {
      window.removeEventListener("resize", determineDisplayability);
      clearInterval((timerRef as any).current);
    };
  }, []);

  if (usingEzoic) {
    if (useWithPartner === "ATD") {
      return null;
    }
    if (screen === "DESKTOP" && EZOIC_MOBILE_PLACEHOLDERS.includes(+ezoicId)) {
      return null;
    }
    if (screen === "MOBILE" && EZOIC_DESKTOP_PLACEHOLDERS.includes(+ezoicId)) {
      return null;
    }
  } else {
    if (useWithPartner === "EZOIC") {
      return null;
    }
  }

  const isMobileOnlyAd = screen === "MOBILE" || id.includes("Mobile");
  const isDesktopOnlyAd = screen === "DESKTOP" || id.includes("Desktop");

  const isAnyScreenAd = screen === "ANY";
  const isMobileAdOnMobile = isMobileOnlyAd && showingMobile;
  const isDesktopAdOnDesktop = isDesktopOnlyAd && !showingMobile;

  if (!(isAnyScreenAd || isMobileAdOnMobile || isDesktopAdOnDesktop)) {
    return null;
  }

  return (
    <InView
      as="div"
      threshold={0.5}
      onChange={(inView) => {
        if (shouldDisplay) {
          log("ad", completeAdId, "shouldDisplay");
          if (inView) {
            log("ad", completeAdId, "inView");
            if (!fired) {
              (timerRef as any).current = setInterval(() => {
                setTime((prev) => {
                  if (prev >= 1000) {
                    clearInterval(timerRef.current);
                    setFired(true);
                    log("ad", completeAdId, "tracked");
                    gtag.event({
                      action: TRACKING_EVENTS.ad_div_impression,
                      category: TRACKING_CATEGORIES.view,
                      label: completeAdId,
                    });
                    ClickServer.track(TRACKING_EVENTS.ad_div_impression, {
                      ad_name: completeAdId,
                    });
                  }
                  return prev + 10;
                });
              }, 10);
            }
          } else {
            log("ad", completeAdId, "not inView");
            setTime(0);
            setFired(false);
            clearInterval((timerRef as any).current);

            (leavesViewRef as any).current = setInterval(() => {
              setTimeOutOfView((prev) => {
                if (prev >= 1000) {
                  clearInterval(leavesViewRef.current);
                  setFired(false);
                  log("ad", completeAdId, "resetTimer");
                  return 0;
                }
                return prev + 10;
              });
            }, 10);
          }
        } else {
          log("ad", completeAdId, "not shouldDisplay");
          clearInterval((timerRef as any)?.current);
          clearInterval((leavesViewRef as any)?.current);
        }
      }}
      ref={containerEl}
      data-testid="qa_ad_container"
      className={clsx(
        className,
        "relative mx-auto print:hidden",
        debugging && !isPartnerEzoic && "min-h-[2.5rem] bg-purple-300",
        isMobileOnlyAd && "md:hidden",
        isDesktopOnlyAd && "hidden md:block",
      )}
      style={style}
      onClick={() => shouldDisplay && adClicked()}
    >
      {!debugging && !isPartnerEzoic && !PRODUCTION_BUILD && (
        <div className="relative left-0 top-0 rounded border border-purple-800 bg-purple-500 text-purple-800">
          {completeAdId}
        </div>
      )}
      {debugging && !isPartnerEzoic && !PRODUCTION_BUILD && (
        <div className="relative top-1/4 z-10 flex w-full flex-col items-center">
          <span
            className={clsx(
              debugClassName,
              "break-all rounded-full border-2 border-black bg-green-300 px-4 text-center font-bold text-gray-800 shadow-2xl",
            )}
          >
            {completeAdId}
          </span>
        </div>
      )}
      <div
        id={shouldDisplay ? completeAdId : ""}
        className="z-0"
        data-testid={shouldDisplay ? "qa_ad_ad" : ""}
      />
    </InView>
  );
}
